import React from 'react';
import Typography from '@components/Typography';
import './ContactForm.scss';
import HubspotForm from 'react-hubspot-form';

const ContactForm = () => {
  return (
    <div className="TDB-ContactForm">
      <div className="TDB-ContactForm__FormWrapper">
        <Typography as="h3" fontSize="heading-3" fontWeight="bold" color="neutral-900" className="TDB-ContactForm__heading">
          Have a general question?
        </Typography>
        <HubspotForm portalId="7741339" formId="53094639-c225-4793-b44e-9dfa4abfcc53" loading={<div>Loading...</div>} />
      </div>
      <div className="TDB-ContactForm__Offices">
        <Typography as="h3" fontSize="heading-3" fontWeight="bold" color="neutral-900" className="TDB-ContactForm__Offices__Heading">
          Our offices
        </Typography>
        <div className="TDB-ContactForm__Offices__Wrapper">
          <div className="TDB-ContactForm__Offices__First">
            <Typography as="h4" fontSize="subheading-1" fontWeight="semi-bold" color="neutral-900">
              United states
            </Typography>
            <Typography as="h5" fontSize="subheading-3" fontWeight="medium" color="neutral-900">
              Headquarters
            </Typography>
            <Typography as="div" color="neutral-600">
              TileDB, Inc.
            </Typography>
            <Typography as="div" color="neutral-600">
              CIC, 1 Broadway
            </Typography>
            <Typography as="div" color="neutral-600">
              Cambridge, MA 02142, USA
            </Typography>
          </div>
          <div className="TDB-ContactForm__Offices__Second">
            <Typography as="h4" fontSize="subheading-1" fontWeight="semi-bold" color="neutral-900">
              Greece
            </Typography>
            <Typography as="div" color="neutral-600">
              Cube, 73 Aiolou st.
            </Typography>
            <Typography as="div" color="neutral-600">
              Athens 10551, Greece
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
