import { Link } from 'gatsby';
import React from 'react';
import ConditionalWrapper from '@components/ConditionalWrapper';
import Typography from '@components/Typography';
import './ContactCard.scss';

interface Props {
  text: string;
  image: any;
  link?: string;
  target?: string;
  onClick?: () => void;
}

const ContactCard: React.FC<Props> = (props) => {
  const { text, image, onClick, link, target } = props;

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.keyCode === 13) {
      // eslint-disable-next-line
      onClick && onClick();
    }
  };

  return (
    <ConditionalWrapper
      condition={!!link}
      left={(children) => (
        <ConditionalWrapper
          condition={!!target}
          left={(innerChildren) => (
            <a className="TDB-ContactCard" href={link} target={target} rel={target === '_blank' ? `noopener noreferrer` : ''}>
              {innerChildren}
            </a>
          )}
          right={(innerChildren) => (
            <Link className="TDB-ContactCard" to={link as string} target={target} rel={target === '_blank' ? `noopener noreferrer` : ''}>
              {innerChildren}
            </Link>
          )}
        >
          {children}
        </ConditionalWrapper>
      )}
      right={(innerChildren) => (
        <div
          className="TDB-ContactCard"
          onClick={onClick}
          tabIndex={0}
          role="button"
          onKeyDown={(event: React.KeyboardEvent) => handleKeyDown(event)}
        >
          {innerChildren}
        </div>
      )}
    >
      <img className="TDB-ContactCard__Image" src={image} alt="text" />
      <Typography fontSize="subheading-1" color="neutral-800" fontWeight="bold">
        {text}
      </Typography>
    </ConditionalWrapper>
  );
};

export default ContactCard;
