import React from 'react';
import './ContactCommunity.scss';
import { Link } from 'gatsby';
import ClassName from '@utils/helpers/ClassName';
import Typography from '@components/Typography';
import links from '@constants/links';
import ConditionalWrapper from '@components/ConditionalWrapper';
import GithubIcon from './GithubIcon';
import ForumIcon from './ForumIcon';
import TwitterIcon from './TwitterIcon';

interface Props {
  className?: string;
}

const ContactCommunity: React.FC<Props> = (props) => {
  const { className } = props;
  return (
    <div className={ClassName.join('TDB-ContactCommunity', className)}>
      <div className="TDB-ContactCommunity__Icons">
        <Typography className="TDB-ContactCommunity__Heading" as="h3" color="neutral-900" fontSize="heading-3" fontWeight="bold">
          Engage with our community
        </Typography>
        <div className="TDB-ContactCommunity__Icons__Wrapper">
          <div className="TDB-ContactCommunity__Icons__Wrapper__Inner">
            <IconButton title="GitHub" subtitle="Contribute code" icon={<GithubIcon />} link={links.allRepos} />
            <IconButton title="Forum" subtitle="Ask the developers" icon={<ForumIcon />} link={links.forum} />
            <IconButton title="Twitter" subtitle="Join the conversation" icon={<TwitterIcon />} link={links.x} />
          </div>
        </div>
      </div>
      <div className="TDB-ContactCommunity__Links">
        <Typography className="TDB-ContactCommunity__Heading" color="neutral-900" fontSize="heading-3" fontWeight="bold">
          Looking for something else?
        </Typography>
        <div className="TDB-ContactCommunity__Links__Wrapper">
          <ExternalLink title="Feature request" subtitle="Upvote or request a feature" link={links.feedback} target="_blank" />
          <ExternalLink title="Pricing" subtitle="Pay only for what you use" link={links.pricing} />
        </div>
      </div>
    </div>
  );
};

interface IconButtonProps {
  title: string;
  subtitle: string;
  icon: React.ReactElement<any, any>;
  link: string;
}

const IconButton: React.FC<IconButtonProps> = (props) => {
  const { title, subtitle, icon, link } = props;
  return (
    <a href={link} target="_blank" rel="noreferrer noopener" className="TDB-ContactCommunity__IconButton">
      {icon}
      <Typography fontSize="subheading-1" fontWeight="semi-bold" color="neutral-main">
        {title}
      </Typography>
      <Typography fontWeight="medium" color="brand-100">
        {subtitle}
      </Typography>
    </a>
  );
};

interface ExternalLinkProps {
  title: string;
  subtitle: string;
  target?: string;
  link: string;
}
const ExternalLink: React.FC<ExternalLinkProps> = (props) => {
  const { title, subtitle, link, target } = props;
  return (
    <ConditionalWrapper
      condition={!!target}
      left={(children) => (
        <a href={link} target="_blank" rel="noreferrer noopener" className="TDB-ContactCommunity__Link">
          {children}
        </a>
      )}
      right={(children) => (
        <Link to={link} className="TDB-ContactCommunity__Link">
          {children}
        </Link>
      )}
    >
      <Typography fontSize="subheading-1" fontWeight="semi-bold" color="neutral-900">
        {title}
      </Typography>
      <div className="TDB-ContactCommunity__Link__Subtitle">
        <Typography fontWeight="semi-bold" color="neutral-600" align="center">
          {subtitle}
        </Typography>
        {target ? <IconNewTab /> : <IconSameTab />}
      </div>
    </ConditionalWrapper>
  );
};

const IconNewTab = () => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0)">
        <path
          d="M15.7905 14.7234C16.0473 14.7234 16.2542 14.6342 16.4112 14.4559C16.5681 14.2775 16.6466 14.0492 16.6466 13.771V8.89138C16.6466 8.52755 16.5521 8.26003 16.363 8.08881C16.174 7.9176 15.9154 7.83199 15.5872 7.83199H10.6755C10.3901 7.83199 10.1636 7.91046 9.99596 8.06741C9.82832 8.22436 9.74449 8.43481 9.74449 8.69877C9.74449 8.95559 9.8301 9.16426 10.0013 9.32477C10.1725 9.48529 10.4008 9.56554 10.6862 9.56554H12.4732L13.8964 9.39433L12.3555 10.8175L8.6637 14.5094C8.46395 14.702 8.36407 14.9231 8.36407 15.1728C8.36407 15.451 8.44968 15.674 8.6209 15.8416C8.79211 16.0093 9.01326 16.0931 9.28435 16.0931C9.42703 16.0931 9.55544 16.0681 9.66958 16.0182C9.78373 15.9682 9.88717 15.8969 9.97991 15.8042L13.6717 12.1231L15.0842 10.5821L14.9237 12.1017V13.7817C14.9237 14.0742 15.0022 14.3043 15.1592 14.4719C15.3161 14.6396 15.5265 14.7234 15.7905 14.7234L15.7905 14.7234ZM6.0206 22H18.9794C20.1351 22 21.0108 21.7057 21.6065 21.1172C22.2022 20.5286 22.5 19.6601 22.5 18.5115V5.4885C22.5 4.33994 22.2022 3.47138 21.6065 2.88282C21.0108 2.29428 20.1351 2 18.9794 2H6.0206C4.8649 2 3.98921 2.29428 3.39353 2.88282C2.79784 3.47137 2.5 4.33993 2.5 5.4885V18.5115C2.5 19.6601 2.79784 20.5286 3.39353 21.1172C3.98921 21.7057 4.8649 22 6.0206 22ZM6.15971 19.8705C5.66747 19.8705 5.29115 19.7421 5.03077 19.4853C4.77038 19.2285 4.64018 18.8397 4.64018 18.3189V5.68115C4.64018 5.16037 4.77038 4.77157 5.03077 4.51475C5.29115 4.25793 5.66747 4.12951 6.15971 4.12951H18.8403C19.3325 4.12951 19.7106 4.25793 19.9746 4.51475C20.2385 4.77157 20.3705 5.16037 20.3705 5.68115V18.3189C20.3705 18.8397 20.2385 19.2285 19.9746 19.4853C19.7106 19.7421 19.3325 19.8705 18.8403 19.8705H6.15971Z"
          fill="#4B5563"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="24" height="24" fill="white" transform="translate(0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
};

const IconSameTab = () => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0)">
        <path
          d="M15.5692 20C15.3156 20.0004 15.0694 19.9145 14.871 19.7566C14.7501 19.6483 14.653 19.5159 14.586 19.368C14.519 19.22 14.4835 19.0598 14.4818 18.8974C14.4801 18.735 14.5122 18.574 14.5762 18.4247C14.6401 18.2755 14.7344 18.1411 14.8531 18.0303L19.7746 13.142C19.7772 13.1395 19.7789 13.1362 19.7797 13.1326C19.7804 13.129 19.78 13.1254 19.7786 13.122C19.7772 13.1186 19.7748 13.1158 19.7718 13.1138C19.7688 13.1118 19.7652 13.1107 19.7616 13.1107H3.1962C2.90769 13.1189 2.62634 13.0201 2.4063 12.8334C2.18625 12.6466 2.04307 12.385 2.00431 12.099C1.99091 11.945 2.00975 11.7898 2.05963 11.6434C2.10951 11.497 2.18934 11.3627 2.29403 11.2488C2.39872 11.135 2.52597 11.0443 2.66768 10.9823C2.80939 10.9204 2.96244 10.8887 3.11708 10.8892H19.7613C19.765 10.8892 19.7685 10.8882 19.7716 10.8862C19.7746 10.8842 19.7769 10.8813 19.7783 10.8779C19.7797 10.8746 19.7801 10.8709 19.7794 10.8673C19.7787 10.8638 19.7769 10.8605 19.7744 10.8579L14.8351 5.95178C14.6253 5.75458 14.4963 5.48637 14.4734 5.19935C14.4505 4.91234 14.5352 4.62706 14.711 4.39906C14.8109 4.28058 14.9342 4.18405 15.0732 4.11557C15.2121 4.04708 15.3638 4.00812 15.5186 4.00114C15.6734 3.99416 15.8279 4.0193 15.9725 4.075C16.1171 4.13069 16.2486 4.21572 16.3587 4.32473L22.7323 10.6554C23.0517 10.9562 23.2594 11.3566 23.3215 11.7909C23.3536 12.0619 23.3234 12.3366 23.2331 12.5942C23.1428 12.8517 22.9949 13.0852 22.8007 13.2769L16.3587 19.6752C16.1489 19.8838 15.865 20.0006 15.5692 20Z"
          fill="#4B5563"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="24" height="24" fill="white" transform="translate(0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ContactCommunity;
