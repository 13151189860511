import * as React from 'react';

interface Props {
  condition: boolean;
  left: (props: React.ReactNode) => any;
  right?: (props: React.ReactNode) => any;
}

const ConditionalWrapper: React.FC<Props> = ({
  condition,
  left,
  right,
  children,
}) => (condition ? left(children) : right ? right(children) : children);

export default ConditionalWrapper;
